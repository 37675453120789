<template>
  <div>
    <b-field>
      <b-numberinput
        v-if="showNumericInput"
        :value="roundTo(value)"
        @input="handleNumericInput"
        :controls="false"
        required
        :disabled="showReadonlyInput"
        @focus="$emit('focus')"
        :max="intMax"
        :step="stepSize"
      />
      <b-input
        v-if="showPercentInput"
        ref="percentinput"
        :value="formattedValue"
        v-currency="percentOptions"
        @input="handlePercentInput"
        required
        class="has-text-right"
        :disabled="showReadonlyInput"
        @focus="$emit('focus')"
      />

      <b-input
        v-if="showMoneyInput"
        ref="moneyinput"
        :value="formattedValue"
        v-currency="moneyOptions"
        @input="handleMoneyInput"
        required
        class="has-text-right"
        :disabled="showReadonlyInput"
        @focus="$emit('focus')"
      />
    </b-field>
  </div>
</template>

<script>
import InputTypes, { FloatTypes, ReadOnlyTypes } from "@/enums/kpi/inputType";
import { parse as parseCurrency, setValue } from "vue-currency-input";
import formatting from "@/common/formatting";

export default {
  props: {
    inputType: { inputType: Number, required: true },
    value: { required: true, default: "" },
    disabled: { type: Boolean }
  },
  data() {
    // previons for float is normally 2, but for readonly float the value is calculated, and so we set precision to 4 so not to lose it on update
    // Note numeric input is ok, but money / %
    var displayPrecision = FloatTypes.includes(this.inputType) ? 2 : 0;
    var updatePrecision = FloatTypes.includes(this.inputType) ? (ReadOnlyTypes.includes(this.inputType) ? 4 : 2) : 0;
    return {
      inputTypes: InputTypes,
      formattedValue: null,
      moneyOptions: { currency: "EUR", allowNegative: false },
      percentOptions: { currency: { suffix: "%" }, valueRange: { min: 0, max: 100 }, precision: updatePrecision },
      displayPrecision: displayPrecision
    };
  },
  computed: {
    showNumericInput() {
      return (
        this.inputType === this.inputTypes.NumericInt ||
        this.inputType === this.inputTypes.AutoInt ||
        this.inputType === this.inputTypes.NumericFloat ||
        this.inputType === this.inputTypes.AutoFloat ||
        this.inputType === this.inputTypes.TotalPreviousInt ||
        this.inputType === this.inputTypes.TotalSection
      );
    },
    intMax() {
      return this.inputType === this.inputTypes.NumericInt ? 1000000 : null;
    },
    stepSize() {
      return this.inputType === this.inputTypes.NumericInt ? 1 : this.inputType === this.inputTypes.NumericFloat ? 0.01 : null;
    },
    showMoneyInput() {
      return (
        this.inputType === this.inputTypes.NumericMoney ||
        this.inputType === this.inputTypes.TotalPreviousMoney ||
        this.inputType === this.inputTypes.AutoMoney
      );
    },
    showPercentInput() {
      return (
        this.inputType === this.inputTypes.NumericPercent ||
        this.inputType === this.inputTypes.AveragePreviousPercent ||
        this.inputType === this.inputTypes.AutoPercent
      );
    },
    showReadonlyInput() {
      return this.disabled || ReadOnlyTypes.includes(this.inputType);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "setValue"
    }
  },
  methods: {
    handleMoneyInput(value) {
      this.$emit("input", parseCurrency(value, this.moneyOptions));
      this.formattedValue = value;
    },
    handlePercentInput(value) {
      this.$emit("input", parseCurrency(value, this.percentOptions));
      this.formattedValue = value;
    },
    setValue(value) {
      if (this.showMoneyInput) {
        // done in nextTick so that the control is added to DOM and so ref will work
        this.$nextTick(() => setValue(this.$refs.moneyinput, value));
      }
      if (this.showPercentInput) {
        this.$nextTick(() => setValue(this.$refs.percentinput, value));
      }
    },
    roundTo(num) {
      return formatting.round(num, 0);
    },
    handleNumericInput(value) {
      if (value === null) value = 0;
      this.$emit("input", value);
    }
  }
};
</script>
