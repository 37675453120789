const InputTypeEnum = {
  Unknown: 0,
  NumericInt: 1,
  NumericFloat: 2,
  NumericMoney: 3,
  NumericPercent: 4,
  Date: 5,
  DateTime: 6,
  YesNo: 7,
  YesNoUnsure: 8,
  Rating: 9,

  AutoInt: 21,
  AutoFloat: 22,
  AutoMoney: 23,
  AutoPercent: 24,

  TotalSection: 100,
  TotalPreviousInt: 101,
  TotalPreviousFloat: 102,
  TotalPreviousMoney: 103,
  AveragePreviousPercent: 104
};

export default InputTypeEnum;

export const NumericTypes = [
  InputTypeEnum.NumericInt,
  InputTypeEnum.NumericFloat,
  InputTypeEnum.NumericMoney,
  InputTypeEnum.AutoInt,
  InputTypeEnum.AutoFloat,
  InputTypeEnum.AutoMoney,
  InputTypeEnum.AutoPercent
];

export const FloatTypes = [
  InputTypeEnum.NumericFloat,
  InputTypeEnum.NumericMoney,
  InputTypeEnum.NumericPercent,
  InputTypeEnum.AutoFloat,
  InputTypeEnum.AutoMoney,
  InputTypeEnum.AutoPercent
];

export const TotalPreviousTypes = [
  InputTypeEnum.TotalPreviousInt,
  InputTypeEnum.TotalPreviousFloat,
  InputTypeEnum.TotalPreviousMoney,
  InputTypeEnum.AveragePreviousPercent
];

export const AutoTypes = [InputTypeEnum.AutoInt, InputTypeEnum.AutoFloat, InputTypeEnum.AutoMoney, InputTypeEnum.AutoPercent];

export const ReadOnlyTypes = [InputTypeEnum.TotalSection, ...TotalPreviousTypes, ...AutoTypes];

// Offset from Total Previous Type to matching Type - they must match in order
export const TotalPreviousTypeOffset = InputTypeEnum.TotalPreviousInt - InputTypeEnum.NumericInt;
