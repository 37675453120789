<template>
  <b-field label="Group" horizontal>
    <b-select placeholder="Select KPI Group" :value="value" @input="selectAuditType" :disabled="disabled">
      <option v-for="option in auditTypeList" :value="option.auditTypeId" :key="option.auditTypeId">
        {{ option.name }}
      </option>
    </b-select>
    <LoadingData v-if="isLoading" />
  </b-field>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingData from "@/components/LoadingData.vue";

export default {
  props: {
    value: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    selectFirstValue: { type: Boolean, default: false },
    addAllOption: { type: Boolean, default: false }
  },
  components: { LoadingData },
  computed: {
    ...mapGetters("auditTypes", ["auditTypes", "firstAuditTypeId", "selectedAuditTypeId"]),
    auditTypeList() {
      if (!this.auditTypes) return [];
      const options = [...this.auditTypes];
      if (this.addAllOption) {
        options.unshift({ auditTypeId: 0, name: "<All>" });
      }
      return options;
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  async created() {
    if (!this.auditTypes) {
      this.isLoading = true;
      await this.$store.dispatch("auditTypes/getAuditTypes");
      this.isLoading = false;
    }
    if (!this.value) {
      if (this.selectedAuditTypeId) {
        this.$emit("input", this.selectedAuditTypeId);
        // select first only if defined in prop, or if there is only one
      } else if (this.selectFirstValue || this.auditTypes?.length === 1) {
        this.selectAuditType(this.firstAuditTypeId);
      }
    }
  },
  methods: {
    selectAuditType(v) {
      this.$emit("input", v);
      this.$store.dispatch("auditTypes/setAuditType", v);
    }
  }
};
</script>
