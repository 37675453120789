<template>
  <b-field :label="label" horizontal class="label-long">
    <b-datepicker
      v-if="showDatePicker"
      ref="datepicker"
      :placeholder="placeholder"
      icon="calendar-today"
      editable
      :show-week-number="showWeeks"
      :week-number-clickable="showWeeks"
      :unselectable-days-of-week="unselectableDays"
      @week-number-click="weekClicked"
      @change-year="yearChanged"
      :type="calType"
      v-model="selectedDate"
      required
      position="is-bottom-left"
      :date-formatter="formatDate"
      :disabled="disabled"
    />
    <b-select v-if="showYearPicker" v-model="yearPicked" :disabled="disabled">
      <option v-for="year in yearOptions" :key="'y' + year" :value="year"> {{ formatDate(year) }}</option>
    </b-select>
    <b-select v-if="showSixMonthsPicker" v-model="sixMonthsPicked" :disabled="disabled">
      <option v-for="sixMonths in sixMonthsOptions" :key="'s' + sixMonths" :value="sixMonths"> {{ formatDate(sixMonths) }}</option>
    </b-select>
    <b-select v-if="showQuarterPicker" v-model="quarterPicked" :disabled="disabled">
      <option v-for="quarter in quarterOptions" :key="'q' + quarter" :value="quarter"> {{ formatDate(quarter) }}</option>
    </b-select>
  </b-field>
</template>

<script>
import ReportingPeriod from "@/enums/reportingPeriod";
import Dates from "@/common/dates";
import _ from "lodash";

export default {
  props: {
    reportingPeriod: { type: Number, required: true, default: ReportingPeriod.Enum.UnKnown },
    reportingWeekDay: { type: Number, required: true, default: 0 },
    toDate: { required: true, default: null },
    fromDate: { required: true, default: null },
    disabled: { type: Boolean }
  },
  data() {
    const currentYear = new Date().getFullYear();
    return {
      periods: ReportingPeriod.Enum,
      selectedDate: null,
      selectedYear: currentYear, // this is used for month picker
      yearOptions: Dates.previousPeriods(ReportingPeriod.Enum.Year, 10),
      yearPicked: null, // this is used for year picker
      sixMonthsOptions: Dates.previousPeriods(ReportingPeriod.Enum.SixMonths, 8),
      sixMonthsPicked: null,
      quarterOptions: Dates.previousPeriods(ReportingPeriod.Enum.Quarter, 16),
      quarterPicked: null
    };
  },
  computed: {
    label() {
      return ReportingPeriod.Label[this.reportingPeriod];
    },
    placeholder() {
      return ReportingPeriod.PlaceHolder[this.reportingPeriod];
    },
    calType() {
      return this.reportingPeriod === this.periods.Month ? "month" : null;
    },
    showDatePicker() {
      return this.reportingPeriod <= this.periods.Month;
    },
    showQuarterPicker() {
      return this.reportingPeriod === this.periods.Quarter;
    },
    showSixMonthsPicker() {
      return this.reportingPeriod === this.periods.SixMonths;
    },
    showYearPicker() {
      return this.reportingPeriod === this.periods.Year;
    },
    showWeeks() {
      return this.reportingPeriod === this.periods.WeekStarting || this.reportingPeriod === this.periods.WeekEnding;
    },
    unselectableDays() {
      return this.showWeeks ? _.range(0, this.reportingWeekDay).concat(_.range(this.reportingWeekDay + 1, 7)) : null;
    }
  },
  watch: {
    selectedDate: "setDateRange",
    reportingPeriod: {
      immediate: true,
      handler: "checkDateRange"
    },
    toDate: {
      immediate: true,
      handler(v) {
        // updating the toDate lets us change the date picker, but we need to set to correct range
        // but for week starting, date is the from date
        if (this.reportingPeriod !== this.periods.WeekStarting) {
          this.updateSelectedDate(v);
          this.checkDateRange();
        }
      }
    },
    fromDate: {
      immediate: true,
      handler(v) {
        // updating the fromDate lets us change the date picker for WeekStarting
        if (this.reportingPeriod === this.periods.WeekStarting) {
          this.updateSelectedDate(v);
          this.checkDateRange();
        }
      }
    },
    quarterPicked: "updateSelectedDate",
    sixMonthsPicked: "updateSelectedDate",
    yearPicked: "updateSelectedDate",
    showQuarterPicker(shown) {
      if (shown) this.quarterPicked = Dates.startOfQuarter(this.selectedDate).getTime();
    },
    showSixMonthsPicker(shown) {
      if (shown) this.sixMonthsPicked = Dates.startOfSixMonths(this.selectedDate).getTime();
    },
    showYearPicker(shown) {
      if (shown) this.yearPicked = Dates.startOfYear(this.selectedDate).getTime();
    }
  },
  methods: {
    weekClicked(week) {
      this.selectedDate = Dates.getDateOfISOWeek(week, this.selectedYear, this.reportingWeekDay);
    },
    yearChanged(y) {
      this.selectedYear = y;
    },
    setDateRange() {
      let fromDate = this.selectedDate;
      let toDate = this.selectedDate;
      switch (this.reportingPeriod) {
        case this.periods.WeekStarting:
          // Date range is inclusive, so add 6 days to get full week
          toDate = Dates.addDays(toDate, 6);
          break;
        case this.periods.WeekEnding:
          fromDate = Dates.addDays(toDate, -6);
          break;
        case this.periods.Month:
          fromDate = Dates.startOfMonth(toDate);
          toDate = Dates.endOfMonth(toDate);
          break;
        case this.periods.Quarter:
          fromDate = Dates.startOfQuarter(toDate);
          toDate = Dates.endOfQuarter(toDate);
          break;
        case this.periods.SixMonths:
          fromDate = Dates.startOfSixMonths(toDate);
          toDate = Dates.endOfSixMonths(toDate);
          break;
        case this.periods.Year:
          fromDate = Dates.startOfYear(toDate);
          toDate = Dates.endOfYear(toDate);
          break;
      }
      // only emit if different
      if (!Dates.sameDateValue(fromDate, this.fromDate) || !Dates.sameDateValue(toDate, this.toDate)) {
        this.$emit("setRange", { from: fromDate, to: toDate });
      }
    },
    formatDate(date) {
      return Dates.periodFormat(date, this.reportingPeriod);
    },
    updateSelectedDate(date) {
      if (date) this.selectedDate = new Date(date);
    },
    checkDateRange() {
      // previous date may not be a valid period
      if (this.selectedDate === null) return;
      var currentDay = this.selectedDate.getDay();
      switch (this.reportingPeriod) {
        case this.periods.WeekStarting:
        case this.periods.WeekEnding:
          if (this.reportingWeekDay !== currentDay) {
            this.selectedDate = Dates.addDays(this.selectedDate, this.reportingWeekDay - currentDay);
          }
          break;
      }
      this.setDateRange();
    }
  }
};
</script>
