<template>
  <section class="section is-main-section">
    <div class="box">
      <div class="columns">
        <div class="column">
          <SelectLocation v-model="search.locationId" />
        </div>
        <div class="column">
          <AuditTypeSelect v-model="search.auditTypeId" :addAllOption="true" />
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="KPIs"
          icon="format-list-numbered"
          :header-icons="headerIcons"
          :tableData="tableData"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          @headerIconClicked="headerIconClicked"
          @contextDefaultClicked="editAudit"
          :isLoading="isLoading"
          :row-class="setRowColour"
        >
          <b-table-column label="Reporting Period" field="toDate" sortable v-slot="props">
            {{ props.row.reportingDate }}
          </b-table-column>
          <b-table-column label="Group" field="name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Location" field="locationName" sortable v-slot="props">
            {{ props.row.locationName }}
          </b-table-column>
          <b-table-column label="Status" field="status" sortable v-slot="props">
            {{ statusLookUp(props.row.status) }}
          </b-table-column>
          <b-table-column label="Due Date" field="dueDate" sortable v-slot="props">
            {{ shortFormat(props.row.dueDate) }}
          </b-table-column>
        </ContextTableCard>
      </div>
    </div>
  </section>
</template>

<script>
import SelectLocation from "@/components/SelectLocation.vue";
import AuditTypeSelect from "@/components/kpi/AuditTypeSelect.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";

import KpiTypes from "@/enums/kpi/audits";
import PermissionsEnum from "@/enums/permissions";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  components: { SelectLocation, AuditTypeSelect, ContextTableCard },
  mixins: [ContextTableMixin],
  data() {
    // header icons based on permissions
    const headerIcons = [IconsMixin.headerIconRefresh()];
    if (this.$store.getters.hasPermission(PermissionsEnum.KPIEntryAdd)) {
      headerIcons.unshift(IconsMixin.headerIconAdd("KPI"));
    }
    return {
      url: "audits",
      entity: "KPIs",
      search: {
        locationId: null,
        auditTypeId: null,
        page: 1,
        sortField: "toDate",
        sortOrder: "desc"
      },
      headerIcons: headerIcons
    };
  },
  watch: {
    "search.auditTypeId": "debounceRefreshData",
    "search.locationId": "debounceRefreshData"
  },
  methods: {
    statusLookUp(status) {
      return KpiTypes.AuditStatusLookup[status];
    },
    setRowColour(row) {
      return KpiTypes.AuditStatusColour[row.status];
    },
    async headerIconClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$router.push({ name: "kpientry" });
          break;
      }
    },
    editAudit() {
      this.$router.push({ name: "kpientry.edit", params: { id: this.selected.auditId } });
    }
  }
};
</script>
