<template>
  <div>
    <div v-if="!isEdit" class="container mt-4">
      <div class="columns">
        <div class="column">
          <AuditTypeSelect v-model="auditTypeId" />
        </div>
      </div>
    </div>

    <XyeaForm
      v-if="auditTypeId"
      :formTitle="tableTitle"
      icon="format-list-numbered"
      @submit="submit"
      :isSaving="isSaving"
      completeButton
      :saveText="saveText"
      :saveDisabled="saveDisabled"
    >
      <div class="columns">
        <div class="column">
          <SelectLocation v-model="locationId" :disabled="isEditAndNotAdmin" :locationName="locationNameReadOnly" required />
        </div>
        <div class="column">
          <reporting-period
            v-if="data.audit"
            :reportingPeriod="data.reportingPeriod"
            :reportingWeekDay="data.reportingWeekDay"
            :fromDate="data.audit.fromDate"
            :toDate="data.audit.toDate"
            @setRange="setRange"
            :disabled="isEditAndNotAdmin"
          />
        </div>
        <div class="column">
          <b-field v-if="data.audit" label="Due Date" horizontal>
            <b-datepicker
              icon="calendar-today"
              editable
              v-model="data.audit.dueDate"
              required
              :disabled="isEditAndNotSecondaryPermission"
            />
          </b-field>
        </div>
      </div>

      <template v-for="category in data.sectionCategories">
        <div class="columns" :key="'c' + category.auditSectionCategoryId">
          <div class="column categoryHeader">
            {{ category.header }}
          </div>
        </div>
        <template v-for="section in category.sections">
          <div class="columns" :key="section.auditSectionId">
            <div class="column sectionHeader">
              {{ section.header }}
            </div>
          </div>
          <div
            class="columns row-space-narrow"
            v-for="question in section.questions"
            :key="'q' + question.auditQuestionId"
            @mousedown="mousedown"
            @mouseup="mouseup"
          >
            <div class="column is-4 has-text-right has-text-weight-bold field-nested">
              <p class="field-label">{{ question.question }}</p>
              <p class="field-advice" v-if="question.advice">{{ question.advice }}</p>
            </div>
            <div class="column is-2">
              <AuditInputType v-model="question.answer.answerValue" :inputType="question.inputType" @focus="inputClicked" />
            </div>
            <div class="column field-nested">
              <b-input
                v-if="question.answer.showNotes || question.answer.notes"
                v-model="question.answer.notes"
                placeholder="Enter notes or observations"
                :ref="'notes' + question.auditQuestionId"
              />
              <a v-else @click.prevent="showNotes(question)">
                <b-icon class="field-label" icon="message" title="Add notes" />
              </a>
            </div>
          </div>
        </template>
      </template>
    </XyeaForm>

    <LoadingData v-if="isLoading" />
  </div>
</template>

<script>
import XyeaForm from "@/components/XyeaForm";
import SelectLocation from "@/components/SelectLocation.vue";
import AuditTypeSelect from "@/components/kpi/AuditTypeSelect.vue";
import AuditInputType from "@/components/kpi/AuditInput.vue";
import ReportingPeriod from "@/components/kpi/ReportingPeriod.vue";
import LoadingData from "@/components/LoadingData.vue";

import Dates from "@/common/dates";
import InputTypes, { NumericTypes, TotalPreviousTypes, TotalPreviousTypeOffset } from "@/enums/kpi/inputType";
import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";

import CheckDirty from "@/mixins/checkDirty";

export default {
  components: {
    XyeaForm,
    SelectLocation,
    AuditInputType,
    AuditTypeSelect,
    ReportingPeriod,
    LoadingData
  },
  mixins: [CheckDirty],
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      locationId: null,
      locationNameReadOnly: "",
      auditTypeId: null,
      data: {},
      focusOnClickCount: 0,
      focusOnClickTimeStamp: null,
      isMousedown: false
    };
  },
  computed: {
    tableTitle() {
      return this.$store.getters["auditTypes/selectedAuditTypeName"];
    },
    isEdit() {
      return this.id > 0;
    },
    ...mapGetters(["isAdmin", "hasPermission"]),
    isEditAndNotAdmin() {
      return this.id > 0 && !this.isAdmin;
    },
    isEditAndNotSecondaryPermission() {
      return this.id > 0 && !this.hasPermission(PermissionsEnum.KPIUpdateDueDate);
    },
    saveText() {
      return this.id > 0 ? "Save Changes" : "Save New";
    },
    saveDisabled() {
      // todo: should there be any resitriction on updating historical data
      return !this.isDirty();
    }
  },
  watch: {
    auditTypeId(id) {
      // get questions if we don't have an id, ie new audit
      if (id && !this.isEdit) this.getAuditQuestions();
    },
    data: {
      deep: true,
      handler() {
        this.calcSectionTotals();
      }
    },
    id: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.auditTypeId = null;
        } else {
          this.getData();
        }
      }
    }
  },
  methods: {
    getAuditQuestions() {
      this.isLoading = true;
      this.$http
        .get("auditTypes/" + this.auditTypeId)
        .then(r => this.mapServerData(r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading Audit Questions"))
        .finally(() => (this.isLoading = false));
    },

    calcSectionTotals() {
      this.data.sectionCategories.forEach(category => {
        category.sections.forEach(section => {
          // find any TotalSection field and add NumericInt, NumericFloat or NumericMoney
          const sectionTotal = section.questions.find(q => q.inputType === InputTypes.TotalSection);

          if (sectionTotal) {
            let total = 0;
            section.questions.forEach(q => {
              total += NumericTypes.includes(q.inputType) ? q.answer.answerValue : 0;
            });
            sectionTotal.answer.answerValue = total;
          }

          // find any TotalPrevious field and add matching type that precede it   // supports one or more
          const totalPreviousIndexes = section.questions
            .map((q, i) => (TotalPreviousTypes.includes(q.inputType) ? { inputType: q.inputType, index: i } : null))
            .filter(i => i);
          totalPreviousIndexes.forEach(totalPreviousIndex => {
            let total = 0;
            let count = 0;
            for (let i = totalPreviousIndex.index - 1; i >= 0; i--) {
              const q = section.questions[i];
              if (q.inputType === totalPreviousIndex.inputType - TotalPreviousTypeOffset) {
                total += q.answer.answerValue;
                count++;
              } else {
                break;
              }
            }
            // Check for averages
            if (section.questions[totalPreviousIndex.index].inputType === InputTypes.AveragePreviousPercent) {
              total = total / count;
            }
            section.questions[totalPreviousIndex.index].answer.answerValue = total;
          });
        });
      });
    },

    validateForm() {
      if (!Dates.validDate(this.data.audit.fromDate) || !Dates.validDate(this.data.audit.toDate)) {
        this.$alerts.showAlert("Invalid Date Specified", "Please select a valid reporing period");
        return false;
      }
      if (!Dates.validDate(this.data.audit.dueDate)) {
        this.$alerts.showAlert("Invalid Date Specified", "Please select a valid Due Date");
        return false;
      }
      return true;
    },

    async submit() {
      if (!this.validateForm()) return;

      this.isSaving = true;
      const data = this.getFormData();

      if (this.isEdit) {
        data.auditId = this.id;
        await this.$http
          .put("audits/" + this.id, data)
          .then(r => {
            this.setNotDirty(true);
            this.$router.push({ name: "kpi" });
            this.$buefy.snackbar.open({ message: "KPI updated", queue: false });
          })
          .catch(e => this.$alerts.showErrorAlert(e, "Error updating KPI details"));
      } else {
        await this.$http
          .post("audits/", data)
          .then(r => {
            this.setNotDirty(true);
            this.$router.push({ name: "kpi" });
            this.$buefy.snackbar.open({ message: "New KPI added", queue: false });
          })
          .catch(e => this.$alerts.showErrorAlert(e, "Error adding new KPI details"));
      }
      this.isSaving = false;
    },

    getFormData() {
      var allAnswers = [];
      if (!this.data.sectionCategories) return null;
      this.data.sectionCategories.forEach(c => {
        c.sections.forEach(s => {
          allAnswers = allAnswers.concat(
            s.questions
              .filter(q => q.answer.answerValue !== null) // Only save questions that have a answer (notes without an answer will not be saved), allows for parital save
              .map(q => {
                return {
                  questionId: q.auditQuestionId,
                  inputType: q.inputType,
                  answerId: q.answerId,
                  answer: q.answer,
                  notes: q.notes,
                  target: q.target
                };
              })
          );
        });
      });

      return {
        auditTypeId: this.auditTypeId,
        locationId: this.locationId,
        status: 0, // todo
        target: 0, // todo
        score: 0, // todo
        fromDate: Dates.formatYYYYMMDD(this.data.audit.fromDate),
        toDate: Dates.formatYYYYMMDD(this.data.audit.toDate),
        dueDate: Dates.formatYYYYMMDD(this.data.audit.dueDate),
        answers: allAnswers
      };
    },

    showNotes(question) {
      question.answer.showNotes = true;
      this.$nextTick(function () {
        this.$refs["notes" + question.auditQuestionId][0].focus();
      });
    },
    getData() {
      this.isLoading = true;
      this.$http
        .get("audits/" + this.id)
        .then(r => this.mapServerData(r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading Audit"))
        .finally(() => (this.isLoading = false));
    },
    setRange(r) {
      this.data.audit.fromDate = r.from;
      this.data.audit.toDate = r.to;
      // default Due Date to day after
      if (Dates.isNullDate(this.data.audit.dueDate)) this.data.audit.dueDate = Dates.addDays(r.to, 1);
    },
    mapServerData(data) {
      this.auditTypeId = data.auditTypeId;
      const currentFromDate = this.data.audit?.fromDate;
      const currentToDate = this.data.audit?.toDate;
      const currentDueDate = this.data.audit?.dueDate;

      this.data = data; // map top level params
      // Readonly location only if we have an id
      if (this.isEdit) {
        this.locationId = data.audit.locationId;
        this.locationNameReadOnly = data.audit.locationName;

        // set dates on read from server, otherwise left as they are
        this.data.audit.fromDate = Dates.dateOrNull(data.audit.fromDate);
        this.data.audit.toDate = Dates.dateOrNull(data.audit.toDate);
        this.data.audit.dueDate = Dates.dateOrNull(data.audit.dueDate);
      } else {
        this.data.audit.fromDate = Dates.dateOrNull(currentFromDate);
        this.data.audit.toDate = Dates.dateOrNull(currentToDate);
        this.data.audit.dueDate = Dates.dateOrNull(currentDueDate);
      }
      this.setNotDirty();
    },
    inputClicked() {
      const now = Date.now();
      if (this.isMousedown && (!this.focusOnClickTimeStamp || now - this.focusOnClickTimeStamp < 5000)) {
        this.focusOnClickTimeStamp = now;
        this.focusOnClickCount++;

        if (this.focusOnClickCount > 5) {
          this.$buefy.snackbar.open({
            message: "Note: you can use the &#60;TAB&#62; key to quickly jump from one entry to the next",
            duration: 5000
          });
          this.focusOnClickCount = -10; // stop message reapearing too quickly
        }
      } else {
        this.focusOnClickCount = 0;
        this.focusOnClickTimeStamp = null;
      }
    },
    mousedown() {
      this.isMousedown = true;
    },
    mouseup() {
      this.isMousedown = false;
    }
  }
};
</script>

<style scoped>
.field-advice {
  opacity: 0.75;
  font-size: 13px;
  margin-right: 1.5rem;
}
</style>
